import Select from 'react-select'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import React, { FC, useEffect, useState } from 'react'
import { Grid } from '@mui/material'

import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'
import PosterTile from '../Tile/PosterTile'
import Loading from '../Loading/Loading'
import { getDataFromUrl } from '../../utils/utils'
import { ContentSeasonData, SelectedSeason } from '../../types/season'
import { VideoContentItem } from '../../types/item'
import { contentFailure, contentStart, contentSuccess } from '../../store/slices/contentSlice'
import { useLazyGetContentSeasonDetailsQuery } from '../../store/services/content/contentApi'
import { RootState } from '../../store'
import { useBreakPoints } from '../../hooks/useBreakPoints'
import './Seasons.scss'

interface SeasonsProps {
  series_id: string
}

const Seasons: FC<SeasonsProps> = ({ series_id }) => {
  const { t, i18n } = useTranslation()
  const [response, setResponse] = useState<ContentSeasonData[] | undefined>(undefined)
  const dispatch = useDispatch()
  const [detailApi] = useLazyGetContentSeasonDetailsQuery()

  const [options, setOptions] = useState<SelectedSeason[] | undefined>(undefined)
  const [selectedOption, setSelectedOption] = useState<SelectedSeason | null>(null)
  const { contentLoading } = useSelector((state: RootState) => state.contentDetails)
  const getQuery = new URLSearchParams(window.location?.search)
  const objParam = getDataFromUrl(getQuery.get('data')!)
  const nextUpId = objParam['next_up_id']

  const { isMobile } = useBreakPoints()

  const verifyAndGetContents = async (item, resultArray) => {
    if (item.collection_type) {
      const collectionItems = await detailApi({ id: item.id })
      for (let i = 0; collectionItems?.data && i < collectionItems?.data?.length; i++) {
        verifyAndGetContents(collectionItems.data[i], resultArray)
      }
    } else {
      resultArray.push(item)
    }
  }

  useEffect(() => {
    dispatch(contentStart())
    const getSeasons = async () => {
      try {
        const items = await detailApi({ id: series_id })
        setResponse(items.data)
        if (items?.data) {
          const optionMenu: SelectedSeason[] = []
          for (let i = 0; i < items?.data?.length; i++) {
            const contentArray: VideoContentItem[] = []
            await verifyAndGetContents(items?.data[i], contentArray)
            const label = items?.data[i]?.name
            const optItem = {
              label: label,
              value: '' + (i + 1),
              items: items.data[i].items ? items.data[i].items : contentArray,
              selectedPosition: i
            }
            if (i === 0) {
              setSelectedOption(optItem)
            }
            if (nextUpId && nextUpId === items.data[i].id) {
              setSelectedOption(optItem)
            }
            optionMenu.push(optItem)
          }
          setOptions(optionMenu)
        }
        dispatch(contentSuccess(response))
      } catch (err) {
        dispatch(contentFailure('' + err))
      }
    }
    getSeasons()
  }, [i18n.language, nextUpId])

  const colourStyles = (isMobile: boolean) => ({
    control: (styles) => ({
      ...styles,
      background: '#404040',
      border: '1px solid #636363',
      width: isMobile ? '100%' : 155,
      height: 45,
      fontSize: isMobile ? 16 : 18,
      borderRadius: 12,
      ':hover': {
        borderColor: 'none',
        outline: 'none'
      }
    }),
    singleValue: (styles) => ({
      ...styles,
      color: '#fff'
    }),
    menu: (styles) => ({
      ...styles,
      width: !isMobile ? 272 : '100%',
      padding: 20,
      marginTop: 20,
      background: '#101010',
      borderRadius: 12,
      border: '1px solid #A7A7A7'
    }),
    option: (styles, { isFocused }) => ({
      ...styles,
      background: isFocused ? '#484848' : 'transparent',
      fontSize: isMobile ? 16 : 20,
      width: !isMobile ? 244 : '100%',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      height: 40,
      cursor: 'pointer',
      lineHeight: 1,
      ':active': {
        background: '#A7A7A7'
      }
    }),
    indicatorSeparator: (styles) => ({
      ...styles,
      display: 'none'
    }),
    dropdownIndicator: (styles) => ({
      ...styles,
      color: '#f20b7e',
      ':hover': {
        color: '#f20b7e'
      }
    })
  })

  return (
    <div className='Seasons-main-content'>
      {contentLoading && <Loading />}
      <div>
        <Grid container>
          <Grid item xs={12} sm={6} md={5} className='season_dropdown' padding={1}>
            <Select
              className='season_dropdown_btn'
              defaultValue={selectedOption}
              onChange={setSelectedOption}
              options={options}
              isSearchable={false}
              styles={colourStyles(isMobile)}
              placeholder={selectedOption?.label}
              value={selectedOption}
            />
          </Grid>
        </Grid>
      </div>
      <h2 className='Seasons-header'>{t('content-detail.all_episodes')}</h2>
      <div className='Seasons-content'>
        <ul>
          {selectedOption &&
            selectedOption.items?.map((item, index) => (
              <li key={index}>
                <PosterTile
                  key={index}
                  item={item}
                  direction={'horizontal'}
                  useThumbnail={true}
                  page={'vod_player'}
                  isDuration
                />
              </li>
            ))}
        </ul>
      </div>
    </div>
  )
}

export default Seasons
